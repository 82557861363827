<template>
<div>
	<div v-for="(filter, index) in filters" :key="filter.position">
		<FilterState
			:index="index"
			:display_option="display_option"
			:selected_filters="selected_filters"
			@filterChange="filterChange"
			@deleteFilter="deleteFilter"
			@fieldSelected="fieldSelected"
		/>
	</div>
	<div class="q-my-md row">
		<q-btn
			outline
			rounded
			size="md"
			icon="add"
			color="primary"
			label="Add new filter criteria"
			@click="addNewField()"
		/>
	</div>
	<div class="buttons">
		<ActionButtons/>
	</div>
</div>
</template>

<script>
import FilterState from '@/components/scenarios/message_reports/detailed_reports/FilterState.vue';
import ActionButtons from '@/components/scenarios/message_reports/detailed_reports/ActionButtons.vue';
import Icon from "@/components/utils/Icon.vue";

export default {
	name: "Filters",
	components: {
		FilterState,
		ActionButtons,
		Icon
	},
	data() {
		return {
			filters: [{position: 0}],
			display_option: "display_count",
			selected_filters: [],
			disabled: false
		}
	},
	methods: {
		checkExtraButtons(label, filter_by){
			this.$store.commit("canResend", false);
			this.$store.commit("canCreateNewMessage", false);
			if(filter_by == 'reply'){
				this.$store.commit("canResend", label === "No Response");
				this.$store.commit("canCreateNewMessage", !(label === "No Response" || label === 'Excluded Users'));
			}
			if(filter_by == 'reply_time'){
				this.$store.commit("canResend", label === "No Response");
				this.$store.commit("canCreateNewMessage", (label === "On Time Response" || label === "Late Response"));
			}
		},
		fieldSelected(selected_label, index, fields_local_length){
			if(fields_local_length == 1){
				this.disabled = true;
			}
			this.checkExtraButtons();
			if(!this.selected_filters.includes(selected_label)){
				if(this.selected_filters[index]){
					this.selected_filters[index] = selected_label;
				}else{
					this.selected_filters.push({index: selected_label})
				}
			}
		},
		filterChange(index, field = "", filter_by = "") {
			this.filters[index].filter_by = filter_by;
			this.filters[index].value = field.value ? field.value : field.label
			this.checkExtraButtons(field.label, filter_by)
			this.$emit('filterChange', this.filters);
		},
		addNewField(){
			this.filters.push({"position": this.filters[this.filters.length - 1].position + 1});
			this.display_option = this.filters.length > 1 ? 'label' : 'display_count'
		},
		deleteFilter(index){
			this.disabled = false;
			this.selected_filters.splice(index, 1)

			let self = this;
			if(this.filters.length > 1){
				this.filters.splice(index,1)
			}
			else{
				this.filters = [{"position": -1}],
				setTimeout(function(){
					self.$set(self.filters[0], 'position', 0)
				}, 1);
			}
			this.display_option = this.filters.length > 1 ? 'label' : 'display_count'
			this.checkExtraButtons();
			this.$emit("filterChange", this.filters);
		},
	},
}
</script>
