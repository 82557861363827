<template>
	<div>
		<q-table
			hide-header
			:columns="columns"
			:key="columns.description"
			:data="paginated_recipients"
			:max="number_of_pages"
			:pagination.sync="pagination"
			:rows-per-page-options="[5, 10, 20, 50]"
			@request="onRequest"
			flat
		>
		 <template v-slot:body-cell-details="props">
			<q-td :props="props">
				<div v-if="!is_operator_extended_access_AS">
					<q-btn size="sm" color="primary" @click="getRecipientDispatches(props.row)" icon="more_horiz" />
				</div>
			</q-td>
		</template>
		<template v-slot:pagination>
				<span class="row items-center justify-end">
					<q-pagination
						v-model="pagination.page"
						:max="number_of_pages"
						:max-pages="6"
						size="md"
						color="grey-8"
						active-color="primary"
						direction-links
						boundary-links
						@click="pageNumber(pagination.page)"
					/>
				</span>
			</template>
		</q-table>
		<q-dialog v-model="open_dialog">
			<RecipientDetails
				v-if="!is_operator_extended_access_AS"
				:recipient="selected_recipient"
				:dispatches_by_recipient="dispatches_by_recipient"
				@close="open_dialog = false"
			/>
		</q-dialog>
	</div>
</template>

<script>
import RecipientDetails from '@/components/scenarios/message_reports/detailed_reports/RecipientDetails.vue';
import DispatchDetailsAPI from '@/services/api/DispatchDetails.js';
import { mapGetters } from 'vuex';

export default {
	name: 'RecipientList',
	components: {
		RecipientDetails,
		DispatchDetailsAPI,
	},
	props:{
		number_of_pages: Number,
	},
	data(){
		return{
			columns: [
			{
				name: 'description',
				align: 'left',
				field: obj => this.getUserDesc(obj),
			},
			{ name: 'details' }
			],
			dispatches_by_recipient: [],
			dispatches_ready: false,
			recipient_id: "",
			open_dialog: false,
			selected_recipient: [],
			pagination: {
				rowsPerPage: 10,
				rowsNumber: 0,
				sortBy: 'created_at',
				descending: true,
				page: 1,
			},
		}
	},
	computed: {
		...mapGetters([
			'logged_user',
			'current_CG',
			"paginated_recipients",
		]),
		is_operator_extended_access_AS() {
			const operator_ea1 = "Allow Operators to view address string (detailed reports)";
			let root_cg_permissions = this.current_CG.root_customer_group_permissions;

			if(root_cg_permissions.indexOf(operator_ea1) > -1 && (this.logged_user.customer_permission == "OPERATOR" || this.logged_user.customer_permission == "SENDER")){
				return true;
			}else{
				return false;
			}
		},
		is_operator_extended_access_ALL() {
			const operator_ea1 = "Allow Operators to view all info (detailed reports)";
			let root_cg_permissions = this.current_CG.root_customer_group_permissions;

			if(root_cg_permissions.indexOf(operator_ea1) > -1 && (this.logged_user.customer_permission == "OPERATOR" || this.logged_user.customer_permission == "SENDER")){
				return true;
			}else{
				return false;
			}
		}
	},
	methods: {
		async getRecipientDispatches(recipient) {
			this.selected_recipient = recipient;
			this.open_dialog = true;
			this.dispatches_by_recipient = await DispatchDetailsAPI.getDispatchesByRecipient(this.$route.params.id, recipient.recipient_id);
			this.dispatches_ready = !!Object.keys(this.dispatches_by_recipient).length;
		},
		pageNumber(page_number){
			this.$emit('change-page', page_number)
		},
		onRequest(props) {
			const { rowsPerPage, page, rowsNumber } = props.pagination
			this.pagination.page = 1
			this.pagination.rowsPerPage = rowsPerPage
			this.pagination.rowsNumber = rowsNumber
			this.$emit('change-page-number', this.pagination.rowsPerPage)
		},
		getUserDesc(obj){
			let data = obj.address_string + ' - '
			if (obj.state == 'Excluded Users'){	data += 'Excluded'	}
			else if(obj.state == 'Successful Delivery'){ data += 'Delivered '}
			else if(obj.state == 'Successful Connection'){ data += 'Joined '}
			else if(obj.state == 'Delivery Failure'){ data += 'Failed '}
			else if(obj.state == 'Still Processing'){ data += 'Processing '}
			else if(obj.state == 'Failed Connection'){ data += 'Failed '}
			else if(obj.state == 'Conference Rejected'){ data += 'Rejected '}
			else { data += obj.state }
			if (!['Excluded Users', 'Failed Connection', 'Delivery Failure', 'Failed Connection'].includes(obj.state) ) {
				if (obj.reply){	data += ' - ' + 'Response: ' + obj.reply }
			}
			return data
		},
	},
}
</script>
<style lang="scss">
.data-wrapper {
	text-align: left;
	border-radius: 0.375rem;
	margin-bottom: 1rem;
	width: 100%;
	background: $grey-1;
	border: 1px solid $grey-4;
}
</style>