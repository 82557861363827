<template>
	<q-fab
		class="flex justify-center q-mt-md"
		style="position: sticky; bottom: 50px;"
		:label="$q.screen.gt.sm ? 'Actions' : ''"
		label-position="left"
		color="primary"
		icon="keyboard_arrow_up"
		direction="up"
	>
		<q-fab-action
			color="primary"
			icon="download"
			:label="$q.screen.gt.sm ? 'Export user list' : ''"
			@click="exportUsersList()"
		>
			<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
				Export user list
			</q-tooltip>
		</q-fab-action>
		<q-fab-action
			color="primary"
			icon="o_bar_chart"
			:label="$q.screen.gt.sm ? 'Visit overview' : ''"
			:to="{ name: 'Dispatch Overview', params: {id: report_details.id }}"
		>
			<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
				Visit overview
			</q-tooltip>
		</q-fab-action>
		<q-fab-action
			v-if="conference_recording && !is_operator_extended_access_AS"
			color="primary"
			icon="volume_up"
			:label="$q.screen.gt.sm ? 'Recording' : ''"
			@click="getRecording()"
		>
			<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
				Recording
			</q-tooltip>
		</q-fab-action>
		<q-fab-action
			v-if="has_dispatches && !is_operator_extended_access_AS"
			color="primary"
			icon="description"
			:label="$q.screen.gt.sm ? 'XLSX' : ''"
			@click="exportDispatchesXLSX()"
		>
			<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
				XLSX
			</q-tooltip>
		</q-fab-action>
		<q-fab-action
			v-if="has_dispatches && !is_operator_extended_access_AS"
			color="primary"
			icon="picture_as_pdf"
			:label="$q.screen.gt.sm ? 'PDF' : ''"
			@click="exportDispatchesPdf()"
		>
			<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
				PDF
			</q-tooltip>
		</q-fab-action>
		<q-fab-action
			v-if="has_dispatches && !is_operator_extended_access_AS"
			color="primary"
			icon="article"
			:label="$q.screen.gt.sm ? 'CSV' : ''"
			@click="exportDispatchesCsv()"
		>
			<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
				CSV
			</q-tooltip>
		</q-fab-action>
	</q-fab>
</template>

<script>
import { mapGetters } from "vuex";
import DispatchDetailsAPI from '@/services/api/DispatchDetails.js';

export default {
	name: "ExportButtons",
	computed: {
		...mapGetters(['logged_user', 'current_CG', 'file', 'paginated_recipients', 'report_details', "query_param"]),
		conference_recording() {
			if (this.report_details.conference_recording && this.report_details.conference_recording.id){
				return true
			}else{
				return false
			}
		},
		is_operator_extended_access_AS() {
			const operator_ea1 = "Allow Operators to view address string (detailed reports)";
			let root_cg_permissions = this.current_CG.root_customer_group_permissions;
			let show_exports = root_cg_permissions.indexOf(operator_ea1) > -1 && (this.logged_user.customer_permission == "OPERATOR" || this.logged_user.customer_permission == "SENDER")
			return show_exports
		},
		has_dispatches() {
			return this.paginated_recipients.length > 0;
		}
	},
	methods: {
		exportDispatchesCsv() {
			this.$emit('exportCsv');
		},
		exportDispatchesPdf() {
			this.$emit('exportPdf');
		},
		exportDispatchesXLSX() {
			this.$emit("exportXlsx");
		},
		async getRecording() {
			let conference_recording_id = this.report_details['conference_recording']['id'];
			let response = await DispatchDetailsAPI.get_conference_recording_url(conference_recording_id);
			if (response.error) {
				this.$store.commit('updateFile', {
					generating: true,
					file_error: true,
					message: response.data,
				})
				this.$q.notify({
					timeout: 6700,
					message: response.data,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return;
			}
			let message = 'Your recording export was scheduled. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.$store.commit('updateFile', {
				generating: true,
				file_error: false,
				message: message
			})
			this.$q.notify(message)
		},
		async exportUsersList() {
			let response = await DispatchDetailsAPI.exportUsersXlsx(this.$route.params.id, this.query_param);
			if (response.error) {
				this.$store.commit('updateFile', {
					generating: true,
					file_error: true,
					message: response.data,
				})
				return;
			}
			let message = 'Your user file export was scheduled with ID: ' + response.file_id
				+ '. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.$store.commit('updateFile', {
				generating: true,
				file_error: false,
				message: message
			})
			this.$q.notify(message)
		},
	},
}
</script>
<style lang="scss" scoped>
	.field-fab-wrapper {
		position: sticky;
		bottom: 20px;
		z-index: 5;
		width: fit-content;
		margin: 10px auto;
	}
</style>