<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<SessionDetails
			v-if="report_details_ready"
			:preferred_timezone="preferred_timezone"
		/>
		<MessageSettings
			v-if="report_details_ready"
			:preferred_timezone="preferred_timezone"
		/>
		<q-card class="data-wrapper-white q-pa-md">
			<Filters
				v-if="report_details_ready"
				@filterChange="filterChange"
			/>
			<RecipientList v-if="report_details_ready"
				:number_of_pages="number_of_pages"
				:number_of_items_per_page="items_per_page"
				:new_page_number="page_number"
				@change-page="changePage"
				@change-page-number="changePageNumber"
			/>
		</q-card>
		<SessionExportButtons />
	</div>
</template>

<script>
import SessionDetails from "@/components/scenarios/message_reports/detailed_reports/SessionDetails.vue";
import MessageSettings from "@/components/scenarios/message_reports/detailed_reports/MessageSettings.vue";
import Filters from '@/components/scenarios/message_reports/detailed_reports/Filters.vue';
import RecipientList from '@/components/scenarios/message_reports/detailed_reports/RecipientList.vue';
import Pagination from '@/components/utils/Pagination.vue';
import { mapActions, mapGetters } from "vuex";
import moment from 'moment-timezone';
import SessionExportButtons from "../components/scenarios/message_reports/detailed_reports/SessionExportButtons.vue";

export default {
	name: "DispatchDetails",
	meta: {
		title: 'Alert Cascade - Detailed Report'
	},
	components: {
		SessionDetails,
		MessageSettings,
		Filters,
		RecipientList,
		Pagination,
	SessionExportButtons
},
	data() {
		return {
			report_details_ready: false,
			items_per_page: 10,
			page_number: 1,
			filters: "",
			preferred_timezone: "",
			is_after: false,
			update_recipients_timeout: ''
		};
	},
	computed: {
		...mapGetters(['count', 'report_details', 'logged_user', 'list_recipients_inited']),
		number_of_pages: function () {
			return Math.ceil(this.count / this.items_per_page);
		}
	},
	methods: {
		...mapActions(["listRecipientsPagination", "getReportDetails", "initListRecipients"]),
		changePage(page_number) {
			this.page_number = page_number;
			this.listRecipients();
		},
		changePageNumber(num_pages) {
			this.items_per_page = num_pages;
			this.page_number = 1;
			this.listRecipients();
		},
		filterChange(filters) {
			this.$store.commit("setQueryParam", "");
			this.filters = filters;
			if (filters) {
				let query_params = "";
				for (let i in filters) {
					query_params += filters[i].value ? filters[i].filter_by + "=" + encodeURIComponent(filters[i].value) + "&" : "";
				}
				this.$store.commit("setQueryParam", query_params);
			}
			this.page_number = 1;
			this.listRecipients();
		},
		async listRecipients() {
			await this.listRecipientsPagination({
				"page_number": this.page_number,
				"items_per_page": this.items_per_page,
				"session_id": this.$route.params.id,
				"filters": this.filters
			});
			if (!this.list_recipients_inited) {
				this.initListRecipients();
			}
		},
		async updateListRecipients() {
			if (this.is_after) {
				return;
			}
			if (this.list_recipients_inited) {
				await this.listRecipients();
				this.update_recipients_timeout = setTimeout(() => {
					this.updateListRecipients();
				}, 15000);
			} else {
				this.update_recipients_timeout = setTimeout(() => {
					this.updateListRecipients();
				}, 15000);
			}
		}
	},
	async created() {
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
		}
		await this.getReportDetails(this.$route.params.id);
		this.updateListRecipients();
	},
	updated() {
		var a = moment(this.report_details.date_start).add(4, 'hours');
		var b = moment.tz('UTC');//now in UTC
		this.is_after = b.isAfter(a);
	},
	destroyed() {
		clearTimeout(this.update_recipients_timeout)
	},
	watch: {
		report_details: {
			deep: true,
			handler() {
				this.report_details_ready = !!Object.keys(this.report_details).length;
			}
		}
	},
}
</script>
<style lang="scss">
	.data-wrapper-white {
		text-align: left;
		border-radius: 0.375rem;
		margin-bottom: 1rem;
		width: 100%;
		text-align: left;
	}
</style>
