<template>
	<ExportButtons
		@exportCsv="exportCsv"
		@exportPdf="exportPdf"
		@exportXlsx="exportXlsx"
	/>
</template>
<script>
import ExportButtons from '@/components/scenarios/message_reports/detailed_reports/ExportButtons.vue';
import MessageFormsAPI from '@/services/api/MessageForm.js';
import DispatchDetailsAPI from '@/services/api/DispatchDetails.js';
import { mapActions, mapGetters } from "vuex";

export default {
	name: "SessionDetails",
	components: {
		ExportButtons,
	},
	data() {
		return {
			check_session_init_interval: null,
			preferred_timezone_preview: "",
		}
	},
	computed: {
		...mapGetters([
			'logged_user',
			'file',
			'timeout_message',
			'error_message',
			'query_param',
			'resend_task_id',
		]),
	},
	methods: {
		...mapActions(['checkUsersStatistic']),
		async exportCsv() {
			let response = await DispatchDetailsAPI.exportDispatchesCsv(this.$route.params.id);
			if (response.error) {
				this.$store.commit('updateFile', {
					generating: true,
					file_error: true,
					message: response.data,
				})
				this.notificationError(response.data)
				return;
			}
			let message = 'Your CSV file export was scheduled with ID: ' + JSON.parse(response)['file_id'] + '. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.$store.commit('updateFile', {
				generating: true,
				file_error: false,
				message: message
			})
			this.$q.notify(message)
		},
		async exportXlsx() {
			let response = await DispatchDetailsAPI.exportDispatchesXlsx(this.$route.params.id);
			if (response.error) {
				this.$store.commit('updateFile', {
					generating: true,
					file_error: true,
					message: response.data,
				})
				this.notificationError(response.data)
				return;
			}
			let message = 'Your XLSX file export was scheduled with ID: ' + JSON.parse(response)['file_id'] + '. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.$store.commit('updateFile', {
				generating: true,
				file_error: false,
				message: message
			})
			this.$q.notify(message)
		},
		async exportPdf() {
			let response = await DispatchDetailsAPI.exportDispatchesPdf(this.$route.params.id, this.query_param);
			if (response.error) {
					this.$store.commit('updateFile', {
					generating: true,
					file_error: true,
					message: response.data,
				})
				this.notificationError(response.data)
				return;
			}
			let message = 'Your PDF file export was scheduled with ID: ' + response['file_id'] + '. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.$store.commit('updateFile', {
				generating: true,
				file_error: false,
				message: message
			})
			this.$q.notify(message)
		},
		removeSuccessMessage() {
			this.$store.commit('updateFile', {
				generating: false,
				message: '',
			});
		},
		removeResendMessage() {
			this.$store.commit('setResendMessage', null);
			clearInterval(this.check_session_init_interval);
		},
		checkSessionInitResult() {
			this.check_session_init_interval = setInterval(async () => {
				let response = await MessageFormsAPI.getRunResult(this.resend_task_id);
				this.$store.commit('setErrorMessage', "");
				this.$store.commit('setTimeoutMessage', "");

				if ('session_id' in response) {

					clearInterval(this.check_session_init_interval);
					setTimeout(()=>{this.check_session_init_interval = null;}, 500);
					this.check_session_init_interval = null;
					this.$store.commit('setResendMessage', null);

					this.$store.commit(
						"setTimeoutMessage",
						"Your message has been successfully initialised, you will be redirected to the report"
					);
					setTimeout( () => {
						this.$store.commit('setTimeoutMessage', "");
						this.$router.push({ name: 'Dispatch Overview', params: {id: response.session_id }});
					}, 2000);

				} else if (response.error) {
					clearInterval(this.check_session_init_interval);
					setTimeout(()=>{this.check_session_init_interval = null;}, 500);

					this.$store.commit('setResendMessage', null);

					if (typeof(response.data) == 'string') {
						this.$store.commit('setErrorMessage',  response.data);
					} else {
						let error_message = "";
						for (let key in response.data) {
							error_message += response.data[key];
						}
						this.$store.commit('setErrorMessage',  error_message);
					}

				}
			}, 1000);
		},
		notificationError(msg){
			this.$q.notify({
				timeout: 6700,
				message: msg,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
	updated() {
		if (this.resend_task_id && !this.check_session_init_interval) {
			this.checkSessionInitResult();
		}
	},
	beforeDestroy() {
		if(this.interval){
			clearInterval(this.interval);
		}
	},
	destroyed(){
		this.$store.commit('setTimeoutMessage', "");
	},
	watch: {
		chartDatas:{
			deep: true,
			handler(){
				this.checkUsersStatistic(this.$route.params.id);
			}
		},
		timeout_message: function(){
			if (this.timeout_message.length){
				this.notificationError(this.timeout_message)
			}
		},
		error_message: function(){
			if (this.error_message.length){
				this.notificationError(this.error_message)
			}
		},
	},
}
</script>
