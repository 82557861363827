<template>
	<q-card style="width: 700px; max-width: 80vw" class="q-pa-md">
		<q-card-section v-if="test_scenarios.length">
			<div><strong>{{ current_CG.customer_group.session_type_name.test_scenario_name }}</strong></div>
			<hr>
			<q-list v-for="test_scenario in test_scenarios" :key="test_scenario.id">
				<q-item tag="label">
					<q-item-section style="word-break: break-all;">
						{{test_scenario.name}}
					</q-item-section>
					<q-item-section avatar>
						<q-radio v-model="selected" :val="test_scenario"/>
					</q-item-section>
				</q-item>
			</q-list>
		</q-card-section>
		<q-card-section v-if="real_scenarios.length">
			<div><strong>{{ current_CG.customer_group.session_type_name.real_scenario_name }}</strong></div>
			<hr>
			<q-list v-for="real_scenario in real_scenarios" :key="real_scenario.id">
				<q-item tag="label">
					<q-item-section style="word-break: break-all;">
						{{real_scenario.name}}
					</q-item-section>
					<q-item-section avatar>
						<q-radio v-model="selected" :val="real_scenario"/>
					</q-item-section>
				</q-item>
			</q-list>
		</q-card-section>
		<q-card-section>
			<Pagination
				:number_of_pages="number_of_pages"
				@change-page="changePage"
			/>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('close')"/>
			<q-btn color="primary" flat label="Select" @click="selectScenario()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import Pagination from '@/components/utils/Pagination.vue';
import { mapGetters } from 'vuex';

export default {
	name: "DisplayScenariosModal",
	components: {
		Pagination
	},
	data () {
		return {
			active_tab: 'test',
			columns: [
				{
					name: 'Select',
					align: 'left',
				},
				{
					name: 'name',
					align: 'left',
					field: row => row.name,
				},
			],
			selected: {},
		}
	},
	props: {
		number_of_pages: Number
	},
	computed:{
		...mapGetters(['real_scenarios', 'test_scenarios', 'current_CG']),
	},
	methods:{
		select_tab(tab){
			if (this.active_tab != tab) {
				this.active_tab = tab;
				this.$emit('open-tab', this.active_tab)
			}
		},
		changePage(page_number) {
			{{page_number}}
			this.$emit('change-page', page_number);
		},
		selectScenario() {
			if (!this.selected){
				this.$q.notify({
					timeout: 6700,
					message: 'Please select a template.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$emit('select-scenario', this.selected);
		},
	},
}
</script>
