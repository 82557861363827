<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<span class="text-h5">{{ report_details.scenario.name }}</span>
		</q-card-section>

		<q-card-section>
			<p>
				WARNING - you are about to resend your message to {{user_stats_data.total_not_responded_recipients}}
				recipient(s) who haven't replied<span v-if="is_session_active">,</span><span v-else>.</span>
				<span v-if="is_session_active"> but your original message is still open for replies.</span>
				Are you sure you wish to proceed?
			</p>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close');" />
			<q-btn flat label="Resend" color="primary" @click="resend_scenario(report_details.id)" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "ResendMessageModal",
	props: {
		"is_session_active": Boolean
	},
	computed: {
		...mapGetters(["user_stats_data", "report_details"])
	},
	methods: {
		...mapActions(["resendMessageToNoReplies"]),
		async resend_scenario(session_id) {
			this.$q.loading.show({
				delay: 400,
			})
			await this.resendMessageToNoReplies(session_id);
			this.$emit('close');
		},
	},
}
</script>
