<template>
	<q-card style="min-width: 350px">
		<q-card-section>
			<div class="text-h6">Dispatches</div>
		</q-card-section>

		<q-card-section>
			<q-table
				v-if="dispatches_by_recipient.length"
				hide-bottom
				:columns="columns"
				:data="dispatches_by_recipient"
				:rows-per-page-options="[0]"
			/>
			<div v-else>
				<p>{{excluded_recipient_reason}}</p>
			</div>
		</q-card-section>

		<q-card-actions align="right" class="text-primary">
			<q-btn flat label="Close" @click="$emit('close')" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone'

export default {
	name: "RecipientDetails",
	props: {
		"recipient": Object,
		"dispatches_by_recipient": Array,
	},
	data() {
		return {
			excluded_recipient_reason: "",
			reason_for_failure: "",
			columns: [
				{
					label: 'Status',
					name: 'Status',
					align: 'left',
					field: obj => this.getObjData(obj),
					headerStyle: 'font-weight:600;'
				},
				{
					label: 'Destination',
					name: 'Destination',
					align: 'left',
					field: 'field_value',
					headerStyle: 'font-weight:600;'
				},
				{
					label: 'Type',
					name: 'Type',
					align: 'left',
					field: 'dispatch_type',
					headerStyle: 'font-weight:600;'
				},
				{
					label: 'Reason for failure',
					name: 'Reason for failure',
					align: 'left',
					field: obj => {
						if (obj.state === 'failed'){
							return obj.reason_for_failure
						}else{
							return ''
						}
					},
					headerStyle: 'font-weight:600;',
				}
			]
		}
	},
	computed: {
		...mapGetters([
			"paginated_recipients",
			"logged_user",
			"report_details"
		]),
	},
	async created(){
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
		}
		if(this.recipient.exclude_reason == 'no_devices'){
			this.excluded_recipient_reason = "The contact did not have any of the devices configured in the message.";
		} else if(this.recipient.exclude_reason == 'deactivated'){
			this.excluded_recipient_reason = "The contact was disabled when this message was triggered.";
		} else if(this.recipient.exclude_reason == 'duplicate_phone_number'){
			this.excluded_recipient_reason = "Excluded - duplicate phone number";
		}
		if (this.report_details.session_type != 'conference'){
			this.columns = this.columns.concat({
				label: 'Message Content',
				name: 'Message Content',
				align: 'left',
				field: obj => {
					return this.report_details.settings[obj.dispatch_type].message
				},
				headerStyle: 'font-weight:600;',
			})
		}
		if (this.report_details.settings.polling_options){
			let additional_columns = [
				{
					label: 'Response',
					name: 'Reply',
					align: 'left',
					field: 'reply',
					headerStyle: 'font-weight:600;',
				},
				{
					label: 'Response Time',
					name: 'Reply Time',
					align: 'left',
					field: 'reply_time',
					format: val => {
						if (val){
							return moment.utc(val).tz(this.preferred_timezone).format('DD/MM/YYYY HH:mm')
						}else{
							return ""
						}
					},
					headerStyle: 'font-weight:600;',
				},
			]
			this.columns = this.columns.concat(additional_columns)
		}
	},
	methods:{
		getObjData(obj){
			if (obj.state == 'conference_accept'){ return 'Joined'}
			if (obj.state == 'conference_reject'){ return 'Rejected'}
			return obj.state[0].toUpperCase() + obj.state.slice(1)
		},
	}
}
</script>
