<template>
	<q-card class="data-wrapper-white q-pt-sm q-pb-none q-px-none row justify-left full-width">
		<q-card-section class="row full-width q-py-none">
			<!-- SENT TO -->
			<div class="row q-pa-none col-lg-4 col-sm-6 col-12">
				<div :style="$q.screen.gt.xs ? 'width: 40%;' : 'width: 100%;'" class="items-top q-px-none"><strong>Sent to</strong></div>
				<div :style="$q.screen.gt.xs ? 'width: 60%;' : 'width: 100%;'">
					<div class="items-top q-px-none" v-if="report_details.recipients.length > 1">
						{{ logical_operator }}
					</div>
					<q-list v-for="(user, index) in report_detail_recipients" :key="index">
						<div
							:class="[{'border-left': user.value}, 'q-px-none']"
							v-if="show_text.includes('show_all_recipients') || (report_details.recipients.length === 1 && index === 0)"
						>
							<div class="break-word" v-if="user.label">{{user.label}}</div>
							<div class="q-pl-sm break-word" v-if="user.value">{{user.value}}</div>
						</div>
					</q-list>
					<q-card-actions class="q-pa-none"
						v-if="!(report_details.recipients.length === 1 && report_detail_recipients.length === 1)"
					>
						<q-btn flat round size="sm" icon="more_horiz" @click="toggleText('recipients', 'show_all_recipients')" />
					</q-card-actions>
				</div>
			</div>
			<!-- SENT BY -->
			<div class="row q-pa-none col-lg-4 col-sm-6 col-12">
				<div :style="$q.screen.gt.xs ? 'width: 40%;' : 'width: 100%;'" class="items-top q-px-none"><strong>Sent by</strong></div>
				<div :style="$q.screen.gt.xs ? 'width: 60%;' : 'width: 100%;'">
					<div class="items-top q-px-none">{{ unique_devices }}</div>
					<div
						v-if="includes_teams && show_text.includes('show_all_sent_to')"
						class="q-px-none"
					>
						<div class="flex items-top">
							<div style="min-width:30px;">
								<q-icon :name="teamsIcon" size='xs'/>
							</div>
							<div class="flex items-top">TEAMS</div>
						</div>
					</div>
					<div
						v-if="includes_slack && show_text.includes('show_all_sent_to')"
						class="q-px-none"
					>
						<div class="flex items-top">
							<div style="min-width:30px;">
								<q-icon :name="slackIcon" size='xs'/>
							</div>
							<div class="flex items-top">SLACK</div>
						</div>
					</div>
					<q-list v-for="(device, index) in session_settings.devices" :key="index">
						<div
							class="q-px-none"
							v-if="show_text.includes('show_all_sent_to')"
						>
							<div class="flex items-center">
								<span style="min-width:30px;" avatar v-if="device.dispatch_type == 'EMAIL'">
									<q-icon name="o_email" size='xs'/>
								</span>
								<span style="min-width:30px;" v-if="device.dispatch_type == 'SMS'">
									<q-icon name="o_sms" size='xs'/>
								</span>
								<span style="min-width:30px;" v-if="device.dispatch_type == 'VOICE'">
									<q-icon name="o_call" size='xs'/>
								</span>
								<span style="min-width:45px;">{{device.dispatch_type}}</span>
								<span class="flex items-center q-pl-sm">{{device.label}}</span>
							</div>
						</div>
					</q-list>
					<q-card-actions	class="q-pa-none">
						<q-btn flat round size="sm" icon="more_horiz" @click="toggleText('sent_to', 'show_all_sent_to')" />
					</q-card-actions>
				</div>
			<!-- EXCLUDED RECIPIENTS  -->
			</div>
			<div class="row q-pa-none col-lg-4 col-sm-6 col-12" v-if="has_excluded_recipients && report_details.excluded_recipients.length">
				<div :style="$q.screen.gt.xs ? 'width: 40%;' : 'width: 100%;'" class="items-top q-pl-none q-pr-sm">
					<strong>Excluded</strong>
				</div>
				<div :style="$q.screen.gt.xs ? 'width: 60%;' : 'width: 100%;'">
					<div class="items-top q-px-none" v-if="report_details.excluded_recipients.length > 1">
						{{excluded_logical_operator}}
					</div>
					<q-list v-for="(user, index) in report_details_excluded_recipients" :key="index">
						<div
							v-if="
								show_text.includes('show_all_excluded_recipients') ||
								(report_details.excluded_recipients.length == 1 && index == 0)
							"
							:class="[{'border-left': user.value}, 'q-px-none', 'items-top']"
						>
							<div v-if="user.label">{{user.label}}</div>
							<div class="q-pl-sm" v-if="user.value">{{user.value}}</div>
						</div>
					</q-list>
					<q-card-actions v-if="!(report_details.excluded_recipients.length == 1 && report_details_excluded_recipients.length == 1)" class="q-pa-none">
						<q-btn flat round size="sm" icon="more_horiz" @click="toggleText('excluded_recipients', 'show_all_excluded_recipients')" />
					</q-card-actions>
				</div>
			</div>
		</q-card-section>
		<!-- GLOBAL MESSAGE  -->
		<q-card-section style="min-width:100%;" class="q-py-none">
			<div class="q-px-none items-center"><strong>Content</strong></div>
			<div
				v-if="session_settings.global_message && (report_details.session_type == 'broadcast'
					|| report_details.session_type == 'sequence')"
			>
				<q-input
					label="MESSAGE"
					v-model="session_settings.global_message_content"
					readonly
					outlined
					class="full-width q-mb-md"
					type="textarea"
				/>
				<div class="row">
					<span class="col-sm-6 col-12 text-left" v-if="sms_attachments_names !== ''">
						<strong>SMS file links: </strong>
						<q-list class="border-left" v-for="(att, index) in sms_attachments_names.split(',')" :key="index">
							<div class="q-pl-sm"> {{att}} </div>
						</q-list>
					</span>
					<span class="col-sm-6 col-12 text-left" v-if="email_attachments_names !== ''">
						<strong>EMAIL file links: </strong>
						<q-list class="border-left" v-for="(att, index) in email_attachments_names.split(',')" :key="index">
							<div class="q-pl-sm"> {{att}} </div>
						</q-list>
					</span>
					<span class="col-sm-6 col-12 text-left" v-if="teams_attachments_names !== ''">
						<strong>TEAMS file links: </strong>
						<q-list class="border-left" v-for="(att, index) in teams_attachments_names.split(',')" :key="index">
							<div class="q-pl-sm"> {{att}} </div>
						</q-list>
					</span>
					<span class="col-sm-6 col-12 text-left" v-if="slack_attachments_names !== ''">
						<strong>SLACK file links: </strong>
						<q-list class="border-left" v-for="(att, index) in slack_attachments_names.split(',')" :key="index">
							<div class="q-pl-sm"> {{att}} </div>
						</q-list>
					</span>
				</div>
			</div>
			<!-- CONFERENCE -->
			<q-list v-if="report_details.session_type == 'conference'">
				<q-item v-if="session_settings.VOICE.message_amd != undefined" class="q-px-none">
					<q-input
						label="ANSWERING MACHINE"
						v-model="session_settings.VOICE.message_amd"
						readonly
						outlined
						class="full-width"
						type="textarea"
					/>
				</q-item>
				<q-item v-if="session_settings.VOICE.message != undefined" class="q-my-sm q-px-none">
					<q-input
						label="LIVE ANSWER"
						v-model="session_settings.VOICE.message"
						readonly
						outlined
						class="full-width"
						type="textarea"
					/>
				</q-item>
			</q-list>
			<!-- SEPARATE CONTENT -->
			<q-list v-if="session_settings.global_message == false && (report_details.session_type == 'broadcast' || report_details.session_type == 'sequence')">
				<div v-for="(content_field, index) in content_fields" :key="index">
					<q-item class="q-px-none" v-if="show_text.includes('show_all_content') || [0].includes(index)">
						<q-input
							:label="content_field.label"
							v-model="content_field.model"
							readonly
							outlined
							class="full-width"
							type="textarea"
						/>
					</q-item>
					<div class="q-pl-sm" v-if="content_field.attachments_names && (show_text.includes('show_all_content') || [0].includes(index))">
						<div class="flex items-center"><strong>{{content_field.label}} file links:</strong></div>
						<div class="flex items-center border-left q-pl-sm" v-for="(att, index) in content_field.attachments_names.split(',')" :key="index">
							{{att}}
						</div>
					</div>
				</div>
				<q-card-actions v-if="content_fields.length > 1" class="q-pa-none">
					<q-btn flat round size="sm" icon="more_horiz" @click="toggleText('content', 'show_all_content')" />
				</q-card-actions>
			</q-list>
		</q-card-section>
		<!-- RESPONSE OPTIONS -->
		<q-card-section class="row justify-left full-width q-pb-none">
			<div
				class="col-lg-4 col-sm-6 col-12 q-pb-sm"
				v-if="
					session_settings.polling_options != null &&
					(report_details.session_type == 'broadcast' || report_details.session_type == 'sequence')
				"
			>
				<div class="item-wrap q-px-none items-top q-pt-none"><strong>Response options: </strong></div>
				<div class="item-wrap" v-for="(polling_option, index) in session_settings.polling_options" :key="index">
					<span> {{polling_option.option_digit}} = {{polling_option.option_text}} </span>
				</div>
			</div>
			<div
				class="col-lg-4 col-sm-6 col-12 q-pb-sm"
				v-if="
					session_settings.polling_options != null &&
					(report_details.session_type == 'broadcast' || report_details.session_type == 'sequence')
				"
			>
				<strong>Response duration:</strong><br>
				<div class="item-wrap q-px-none items-top q-pt-none row">
					<div style="width: 70px;">On time:</div>
					{{on_time}} from {{start_date}} {{start_time}} to&nbsp;
					<div v-if="start_date != timeout_finish_date">{{timeout_finish_date}}&nbsp;</div>
					{{timeout_finish}}
					<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
						{{preferred_timezone_preview}}
					</q-tooltip>
				</div>
				<div class="item-wrap q-px-none items-top q-pt-none row">
					<div style="width: 70px;"> Late:</div>
					1h from {{timeout_finish_date}} {{timeout_finish}} to
					<div v-if="timeout_finish_date != end_date">{{end_date}}</div>
					{{end_time}}
					<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
						{{preferred_timezone_preview}}
					</q-tooltip>
				</div>
				<div class="item-wrap q-px-none items-top q-pt-none row">
					<div style="width: 70px;">Total:</div>
					{{all_time}} from {{start_date}} {{start_time}} to&nbsp;
					<div v-if="start_date != end_date">{{end_date}}</div>
					{{end_time}}
					<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
						{{preferred_timezone_preview}}
					</q-tooltip>
				</div>
			</div>
			<div
				class="col-lg-4 col-sm-6 col-12 q-pb-sm"
				v-if="report_details.session_type == 'broadcast' || report_details.session_type == 'sequence'"
			>
				<q-list>
					<div><strong> Re-call settings: </strong></div>
					<div class="item-wrap q-px-none items-top q-pt-none row">
						<div style="width: 120px;">Re-call recipients:</div>
						<div v-if="session_settings.auto_retry">Yes</div>
						<div v-else>No</div>
					</div>
					<div class="item-wrap q-px-none row" v-if="session_settings.auto_retry && session_settings.auto_retry_minutes">
						<div style="width: 120px;">After:</div>
						<div>{{session_settings.auto_retry_minutes}} minutes </div>
					</div>
				</q-list>
			</div>
			<div
				class="col-lg-4 col-sm-6 col-12 q-pb-sm"
				v-if="report_details.session_type == 'conference'"
			>
				<q-list>
					<div><strong> Re-call settings: </strong></div>
					<div class="item-wrap q-px-none items-top q-pt-none row">
						<div style="width: 120px;">Re-call recipients:</div>
						<div v-if="session_settings.recall_participants">Yes</div>
						<div v-else>No</div>
					</div>
				</q-list>
			</div>
		</q-card-section>
	</q-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment-timezone'
import {fabSlack} from "@quasar/extras/fontawesome-v5";
import {mdiMicrosoftTeams} from "@quasar/extras/mdi-v5";

export default {
	name: "MessageSettings",
	props: {
		"preferred_timezone": String,
	},
	data() {
		return {
			show_details: false,
			session_settings:{},
			start_time:'',
			start_date: '',
			timeout_finish:'',
			timeout_finish_date:'',
			end_time:'',
			end_date:'',
			on_time: '',
			all_time: '',
			preferred_timezone_preview: '',
			show_text: [],
			is_recipients: false,
			is_sent_to: false,
			is_excluded_recipients: false,
			is_content: false,
			is_response_options: false,
			content_fields: [],
			includes_teams: false,
			includes_slack: false,
			unique_devices: '',
		}
	},
	computed: {
		...mapGetters(['report_details', 'logged_user']),
		logical_operator() {
			if (this.report_details.recipients[0].logical_operator === 'AND') {
				return 'People who meet ALL of this criteria:';
			} else {
				return 'People who meet ANY of this criteria:';
			}
		},
		excluded_logical_operator() {
			if (this.report_details.excluded_recipients[0].logical_operator === 'AND') {
				return 'People who meet ALL of this criteria:';
			} else {
				return 'People who meet ANY of this criteria:';
			}
		},
		has_excluded_recipients() {
			return !(this.report_details.excluded_recipients.length == 1 && !Object.keys(this.report_details.excluded_recipients[0]).length);
		},
		email_attachments_names() {
			let email_attachments_names="";
			if(this.session_settings.EMAIL != undefined){
				if(this.session_settings.EMAIL.email_attachments != undefined){
					let attachments = this.session_settings.EMAIL.email_attachments;
					for(let i=0; i<attachments.length; i++){
						email_attachments_names += attachments[i].attachment_name+", ";
					}
				}
			}

			if(email_attachments_names.endsWith(', ')){
				email_attachments_names = email_attachments_names.substring(0, email_attachments_names.length-2);
			}

			return email_attachments_names;
		},
		teams_attachments_names(){
			let teams_attachments_names = "";
			if(this.session_settings.microsoft_teams !== undefined){
				if(this.session_settings.microsoft_teams.attachments !== undefined){
					let attachments = this.session_settings.microsoft_teams.attachments;
					for(let i=0; i<attachments.length; i++){
						teams_attachments_names += attachments[i].attachment_name + ", ";
					}
				}
			}
			if(teams_attachments_names.endsWith(', ')){
				teams_attachments_names = teams_attachments_names.substring(0, teams_attachments_names.length-2);
			}
			return teams_attachments_names;
		},
		slack_attachments_names(){
			let slack_attachments_names = "";
			if(this.session_settings.slack !== undefined){
				if(this.session_settings.slack.attachments !== undefined){
					let attachments = this.session_settings.slack.attachments;
					for(let i=0; i<attachments.length; i++){
						slack_attachments_names += attachments[i].attachment_name + ", ";
					}
				}
			}
			if(slack_attachments_names.endsWith(', ')){
				slack_attachments_names = slack_attachments_names.substring(0, slack_attachments_names.length-2);
			}
			return slack_attachments_names;
		},
		sms_attachments_names() {
			let sms_attachments_names="";
			if (this.session_settings.SMS != undefined){
				if(this.session_settings.SMS.sms_attachment_files != undefined){
					let attachments = this.session_settings.SMS.sms_attachment_files;
					for(let i=0; i<attachments.length; i++){
						sms_attachments_names += attachments[i].name+", ";
					}
				}
			}
			if(sms_attachments_names.endsWith(', ')){
				sms_attachments_names = sms_attachments_names.substring(0, sms_attachments_names.length-2);
			}

			return sms_attachments_names;
		},
		report_detail_recipients() {
			let report_detail_recipients = []
			for (let report of this.report_details.recipients){
				report_detail_recipients.push({'label': report.type})
				if (report.values){
					for (let item of report.values){
						report_detail_recipients.push({'value': item})
					}
				}
			}
			return report_detail_recipients
		},
		report_details_excluded_recipients(){
			let report_details_excluded_recipients = []
			if (this.report_details.excluded_recipients.length){
				for (let report of this.report_details.excluded_recipients){
					report_details_excluded_recipients.push({'label': report.type})
					if (report.values){
						for (let item of report.values){
							report_details_excluded_recipients.push({'value': item})
						}
					}
				}
			}
			return report_details_excluded_recipients

		},
		teamsIcon(){
			return mdiMicrosoftTeams
		},
		slackIcon(){
			return fabSlack
		}
	},
	methods: {
		toggleDetails(e) {
			if(e.target.open){
				this.show_details = true;
			}
			else{
				this.show_details = false;
			}
		},
		getTimeBetween(start_time, end_time){
			let minutes = end_time.diff(start_time, 'minutes') % 60
			let hours =  end_time.diff(start_time, 'hours')

			if (minutes > 0 && hours == 0){
				return minutes + 'm'
			}else if (minutes == 0 && hours > 0){
				if (hours > 24) {
					let days = parseInt(hours / 24)
					return days > 1 ? days + ' days' : days + ' day'
				} else {
					return hours + 'h'
				}
			}else{
				return hours + 'h:' + minutes + "m"
			}

		},
		toggleText(type, show_all_value) {
			if (type == 'recipients'){
				this.is_recipients = !this.is_recipients
			}else if (type == 'sent_to'){
				this.is_sent_to = !this.is_sent_to
			}else if (type == 'excluded_recipients'){
				this.is_excluded_recipients = !this.is_excluded_recipients
			}else if (type == 'content'){
				this.is_content = !this.is_content
			}else if (type == 'response_options'){
				this.is_response_options = !this.is_response_options
			}
			if (this['is_' + type]){
				this.show_text.push(show_all_value)
			}else{
				let index = this.show_text.indexOf(show_all_value);
				if (index > -1) {
					this.show_text.splice(index, 1);
				}
			}
		},
		createContentFields(){
			if (this.session_settings.VOICE && this.session_settings.VOICE != undefined){
				this.content_fields.push({
					'model': this.session_settings.VOICE.message_amd,
					'label': 'ANSWERING MACHINE'
				})
			}
			if (this.session_settings.VOICE && this.session_settings.VOICE.message != undefined){
				this.content_fields.push({
					'model': this.session_settings.VOICE.message,
					'label': 'LIVE ANSWER'
				})
			}
			if (this.session_settings.SMS != undefined){
				let item = {
					'model': this.session_settings.SMS.message,
					'label': 'SMS'
				}
				if (this.sms_attachments_names != ''){
					item['attachments_names'] = this.sms_attachments_names
				}
				this.content_fields.push(item)
			}
			if (this.session_settings.EMAIL != undefined){
				let item = {
					'model': this.session_settings.EMAIL.message,
					'label': 'EMAIL'
				}
				if (this.email_attachments_names != ''){
					item['attachments_names'] = this.email_attachments_names
				}
				this.content_fields.push(item)
			}
			if (this.session_settings.microsoft_teams){
				let item = {
					'model': this.session_settings.microsoft_teams.message_content_teams,
					'label': 'TEAMS'
				}
				if (this.teams_attachments_names != ''){
					item['attachments_names'] = this.teams_attachments_names
				}
				this.content_fields.push(item)
			}
			if (this.session_settings.slack){
				let item = {
					'model': this.session_settings.slack.message_content_slack,
					'label': 'SLACK'
				}
				if (this.slack_attachments_names != ''){
					item['attachments_names'] = this.slack_attachments_names
				}
				this.content_fields.push(item)
			}
		},
	},
	created() {
		this.session_settings = this.report_details.settings;
		this.preferred_timezone_preview = `Timezone: ${this.preferred_timezone}`;
		this.createContentFields()

		let start_time = moment.tz(this.report_details.date_start, 'UTC');
		this.start_date = moment.utc(start_time).tz(this.preferred_timezone).format('DD/MM/YYYY');
		this.start_time = moment.utc(start_time).tz(this.preferred_timezone).format('HH:mm');

		let timeout_finish = moment.tz(this.report_details.timeout_finish, 'UTC');
		this.timeout_finish_date = moment.utc(timeout_finish).tz(this.preferred_timezone).format('DD/MM/YYYY');
		this.timeout_finish = moment.utc(timeout_finish).tz(this.preferred_timezone).format('HH:mm');

		let end_time = moment.tz(this.report_details.timeout_finish, 'UTC').add(1, "hours")
		this.end_date = moment.utc(end_time).tz(this.preferred_timezone).format('DD/MM/YYYY');
		this.end_time = moment.utc(end_time).tz(this.preferred_timezone).format('HH:mm');

		this.on_time = this.getTimeBetween(start_time, timeout_finish)
		this.all_time = this.getTimeBetween(start_time, end_time)
		this.includes_teams = this.report_details.settings.microsoft_teams &&
			this.report_details.settings.microsoft_teams.selected_webhooks.length
		this.includes_slack = this.report_details.settings.slack &&
			this.report_details.settings.slack.selected_webhooks.length
		let unique_devices = [...new Set(this.session_settings.devices.map(item => item.dispatch_type))]
		if (this.includes_teams){
			this.unique_devices += 'TEAMS, '
		}
		if (this.includes_slack){
			this.unique_devices += 'SLACK, '
		}
		for (let index in unique_devices){
			if (index != unique_devices.length - 1){
				this.unique_devices += unique_devices[index] + ', '
			}else{
				this.unique_devices += unique_devices[index]
			}
		}
	},
}
</script>
<style lang="scss" scoped>
	.border-left{
		border-left: 1px solid $grey-5;
	}
	.item-wrap{
		flex-wrap: wrap!important;
	}
	.break-word{
		word-break: break-all;
	}
	.set-width{
		width: 33%;
		@media(max-width: 1022px){
			width: 50%;
		}
		@media(max-width: 599px){
			width: 100%;
		}
	}
</style>
