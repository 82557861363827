<template>
	<div class="row">
		<div class="col-sm-6 col-12">
			<q-select
				v-model="field"
				:options="fields_local"
				label="Search by"
				@input="fetchFields(false)"
			>
				<span slot="noOptions">{{options_label}}</span>
			</q-select>
		</div>
		<div class="col-sm-5 col-12" v-if="field.options && field.options.length > 0">
			<q-select
				v-model="filter_value"
				:options="field.options"
				label="Select value"
				:option-label="item => getLabel(item)"
			/>
		</div>
		<div class="col-sm-5 col-12" v-if="field.options && field.options.length == 0">
			<q-input
				v-model="filter_value"
				:hint="filter_value.length < 3 ? 'You need at least 3 characters' : ''"
				label="Enter value"
			/>
		</div>
		<div v-if="selected_filters.length > 1" class="flex items-center">
			<q-btn
				v-if="Object.keys(field).length > 0"
				flat
				round
				icon="close"
				color="primary"
				size="md"
				@click="deleteFilter()"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "FilterState",
	props: {
		index: Number,
		selected_filters: Array,
	},
	data (){
		return {
			fields_local: [],
			field: "",
			filter_value: "",
			old_filter_value: "",
			options_label: "Loading..."
		}
	},
	computed: {
		...mapGetters(["all_dataset_fields", "report_details", "user_stats_data"])
	},
	methods: {
		...mapActions(["getAllDatasetFields"]),
		async fetchFields(from_created){
			await this.getAllDatasetFields(this.report_details);
			await this.fetchFieldOptions();
			this.fields_local = [];
			let index_for_default_field = 0;
			for(let i in this.all_dataset_fields){
				if(!this.selected_filters.includes(this.all_dataset_fields[i].label) || this.all_dataset_fields[i].field_type == "MULTISELECT"){
					this.fields_local.push(this.all_dataset_fields[i])
					if(this.all_dataset_fields[i].filter_by == this.$route.query.section){
						index_for_default_field = this.fields_local.length - 1;
					}
				}
			}
			if(from_created && this.fields_local[0].filter_by == 'state'){
				this.field = this.fields_local[index_for_default_field];
			}else{
				this.filter_value = "";
			}

			await this.fetchFieldOptions();
			if(!this.fields_local.length){
				this.options_label = 'List is empty';
			}
			else{
				this.options_label = 'Loading...';
			}
		},
		async fetchFieldOptions(){
			if(this.field.on_selected){
				let index_for_default_option = 0;
				this.field.options = await this.field.on_selected(this.$route.params.id, this.field.filter_by);
				for(let i in this.field.options){
					if(this.field.options[i].label == this.$route.query.filter){
						index_for_default_option = i;
					}
				}
				this.filter_value = this.field.options[index_for_default_option]
			}
			else{
				this.$emit('filterChange', this.index)
			}
			this.$emit('fieldSelected', this.field.label, this.index, this.fields_local.length)
		},
		deleteFilter(){
			this.$emit('deleteFilter', this.index);
		},
		getLabel(item){
			if (item.label == 'Successful Delivery'){ return 'Delivered' }
			else if (item.label == 'Successful Connection') { return 'Joined'}
			else if (item.label == 'Delivery Failure') { return 'Failed'}
			else if (item.label == 'Still Processing') { return 'Processing'}
			else if (item.label == 'Excluded Users') { return 'Excluded'}
			else if (item.label == 'Failed Connection' ) { return 'Failed'}
			else if (item.label == 'Conference Rejected') {return 'Rejected'}
			return item.label
		}
	},
	watch: {
		filter_value: function(){
			if(this.old_filter_value){
				if(this.filter_value.label == this.old_filter_value) {return;}
			}
			this.old_filter_value = this.filter_value.label
			if(this.filter_value){
				let value = this.filter_value;

				if(this.filter_value.label == "All"){
					this.$emit("filterChange", this.index)
					return
				}
				if(typeof(this.filter_value) == 'string'){
					if(this.filter_value.length < 3){
						this.$store.commit("setSearchErrors", "")
						return
					}
					value = {"field_id": this.field.id, "value": value};
				}
				this.$emit("filterChange", this.index, value, this.field.filter_by)
			}
		},
		user_stats_data: {
			deep: true,
			async handler() {
				if(this.field.on_selected){
					this.field.options = await this.field.on_selected(this.$route.params.id, this.field.filter_by)
					for(let i in this.field.options){
						if(this.filter_value.label === this.field.options[i].label){
							this.filter_value = this.field.options[i];
							break;
						}
					}
				}
			}
		}
	},
	async created(){
		await this.fetchFields(true)
	}
};
</script>