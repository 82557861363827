import { render, staticRenderFns } from "./RecipientList.vue?vue&type=template&id=4198ace3&"
import script from "./RecipientList.vue?vue&type=script&lang=js&"
export * from "./RecipientList.vue?vue&type=script&lang=js&"
import style0 from "./RecipientList.vue?vue&type=style&index=0&id=4198ace3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTd,QBtn,QPagination,QDialog});
