<template>
	<div class="row q-my-md justify-between">
		<q-btn
			v-if="can_create_new_message"
			outline
			rounded
			color="primary"
			icon="o_chat"
			label="Send Message"
			@click="displayScenarioModal()"
		/>
		<q-dialog v-model="show_scenario_modal">
			<DisplayScenariosModal
				:number_of_pages="number_of_pages"
				@change-page="changePage"
				@select-scenario="redirect"
				@close="show_scenario_modal = false"
			/>
		</q-dialog>
		<q-btn
			v-if="can_resend"
			outline
			rounded
			color="primary"
			icon="o_sync"
			label="Resend message"
			@click="resendModal()"
		/>
		<q-dialog v-model="show_resend_modal">
			<ResendMessageModal
				:report_details="report_details"
				:is_session_active="is_session_active"
				@close="show_resend_modal = false"
			/>
		</q-dialog>
		<q-btn 
			v-if="can_create_new_message && logged_user.customer_permission !== 'SENDER'"
			outline
			rounded
			color="primary"
			icon="o_edit"
			label="Create new message"
			@click="redirectCreate()"
		/>
	</div>
</template>

<script>
import ResendMessageModal from '@/components/scenarios/message_reports/detailed_reports/ResendMessageModal.vue';
import DisplayScenariosModal from '@/components/utils/DisplayScenariosModal.vue';
import moment from 'moment-timezone'

import { mapGetters, mapActions } from 'vuex';

export default {
	name: "ActionButtons",
	components: {
		ResendMessageModal,
		DisplayScenariosModal,
	},
	data() {
		return {
			show_resend_modal: false,
			show_scenario_modal: false,
			is_session_active: false,
			page_number: 1,
			page_size: 10,
			items_per_page: 10,
		}
	},
	computed: {
		...mapGetters([
			"file",
			"can_resend",
			"can_create_new_message",
			"users_with_replies",
			"report_details",
			"total_number_of_scenarios",
			"logged_user"
		]),
		number_of_pages: function() {
			return Math.ceil(this.total_number_of_scenarios/this.items_per_page);
		}
	},
	methods: {
		...mapActions(["set_active_item", "listScenariosForModal"]),
		async displayScenarioModal() {
			await this.listScenarios();
			this.show_scenario_modal = true;
		},
		async resendModal(){
			let session_timeout_finish = new Date(this.report_details.timeout_finish);
			session_timeout_finish = moment(session_timeout_finish).format()
			const date_now = moment.utc().format()
			if(session_timeout_finish > date_now){
				this.is_session_active = true;
			}
			this.show_resend_modal = true;
		},
		redirect(scenario) {
			this.show_scenario_modal = false
			let routeData = this.$router.resolve({
				name: 'Edit Message Content',
				params: { id: scenario.id },
				query: { users:this.users_with_replies },
			});
			window.open(routeData.href, '_blank');
			this.set_active_item(202);
		},
		async listScenarios() {
			await this.listScenariosForModal({
				"sessions_flags": false,
				"scheduled_flag": false,
				"page_number": this.page_number,
				"page_size": this.page_size,
				"active_tabs": 'all'
			})
		},
		changePage(page_number) {
			this.page_number = page_number;
			this.listScenarios();
		},
		redirectCreate(){
			let routeData = this.$router.resolve({
				name: 'Create Message',
				query: { users: this.users_with_replies },
			});
			window.open(routeData.href, '_blank');
			this.set_active_item(201);

		}
	},
}
</script>
