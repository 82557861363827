<template>
	<div class="icon-wrapper noselect" ref="wrapper_div" :class="{ 'disabled': disabled, 'push-left': button }">

		<!-- If you want to have text next to the button,
			then the class we are using is add-text -->
		<div
			:class="{
				'delete-field-hover': hover,
				'add-text': extra_text,
				'hover-element-color' : text_hover && !disabled,
			}"
			@click="iconClicked"
			@mouseover="show_tooltip = text_hover = true;"
			@mouseleave="show_tooltip = text_hover = false;"
		>
			<q-icon v-if="name" class="q-mb-md" :name ="name"/>
			<i v-else :class="icon"></i>
			<div v-if="extra_text" class="extra-text">
				{{ extra_text }}
			</div>
		</div>

		<!-- Promt with message -->
		<transition name="fade">
			<div class="prompt-dialog" v-if="show_prompt">
				<p>{{ prompt_msg }}</p>
				<div class="btns-flex">
					<button class="dialog-btn confirm" @click="confirm()">
						<i class="fa fa-check"></i>
					</button>
					<button class="dialog-btn cancel" @click="cancel()">
						<i class="fa fa-times"></i>
					</button>
				</div>
			</div>
		</transition>

		<transition name="tooltip">
			<div class="tooltip-window" v-if="tooltip && show_tooltip && !disabled">
				{{ tooltip }}
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "Icon",
	data() {
		return {
			show_prompt: false,
			show_tooltip: false,
			text_hover: false
		};
	},
	props: {
		icon: String,
		name: String,
		prompt_msg: String,
		extra_text: String,
		tooltip: String,
		hover: Boolean,
		disabled: Boolean,
		button: Boolean
	},
	methods: {
		iconClicked() {
			if (!this.disabled) {
				if (this.prompt_msg) {
					this.show_prompt = true;
				} else {
					this.$emit("iconClicked");
				}
			}
		},
		confirm() {
			this.show_prompt = false;
			this.$emit("iconClicked");
		},
		cancel() {
			this.show_prompt = false;
		},
		documentClick(e) {
			let el = this.$refs.wrapper_div;
			let target = e.target;
			if (el != target && !el.contains(target)) {
				this.show_prompt = false;
			}
		}
	},
	created() {
		document.addEventListener("click", this.documentClick);
	},
	destroyed() {
		document.removeEventListener("click", this.documentClick);
	}
};
</script>

<style scoped>
.icon-wrapper {
	position: relative;
	align-self: center;
	cursor: pointer;
}

.disabled * {
	filter: contrast(0.5);
	cursor: not-allowed;
}

/* Red circle around the button */
.delete-field-hover {
	background: transparent;
	width: 30px;
	height: 30px;
	border: 0 solid transparent;
	border-radius: 100%;
	transition: all 200ms ease-out;
	backface-visibility: hidden;
	text-align: center;
}

.delete-field-hover:hover {
	background: var(--ac-incident-hover-color);
	transform: scale(1.25);

}

/* Text next to the button */
.extra-text {
	margin-left: 2px;
}

.add-text {
	display: flex;
	align-items: center;
}

.prompt-dialog {
	position: absolute;
	background: rgba(105, 105, 105, 0.9);
	color: #fff;
	text-align: center;
	top: 1rem;
	right: -90px;
	border-radius: 10px;
	padding: 10px;
	margin-top: 0.5rem;
	z-index: 5;
	width: 200px;
}

@media (max-width: 425px) {
	.prompt-dialog {
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -100px;
		margin-top: -50px;
	}
}

.tooltip-window {
	position: absolute;
	background: rgba(50, 50, 50, 0.9);
	color: #fff;
	top: 120%;
	left: -30%;
	padding: 5px 10px;
	border-radius: 5px;
	text-align: center;
	font-size: 0.9em;
	z-index: 5;
}

.btns-flex {
	display: flex;
}

.dialog-btn {
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	padding: 2px 10px;
	margin: 0 10px;
	border: none;
	border-radius: 5px;
	flex: 1;
}

.confirm:hover {
	background-color: #118f99;
}

.cancel:hover {
	background-color: #b83b22;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	transform: translateY(-10px);
	opacity: 0;
}

.tooltip-enter-active {
	transition: all 0.5s 1s;
}

.tooltip-enter, .tooltip-leave-to /* .fade-leave-active below version 2.1.8 */ {
	transform: translateY(-5px);
	opacity: 0;
}

.push-left{
	margin-left: auto;
}

.far:hover {
	color: var(--ac-highlight-color);
}
</style>
